@font-face {
  font-display: swap;
  font-family: "Robotronics Regular";
  font-style: normal;
  font-weight: normal;
  src:
    local("../../public/Robotronics Regular"),
    url("../../public/Robotronics.woff") format("woff");
}

h1 {
  font-family: "Robotronics Regular";
  font-weight: 200;
  color: #0ccac4;
}
