body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: linear-gradient(
    to left,
    rgba(7, 27, 82, 1) 0%,
    rgba(0, 128, 128, 1) 100%
  );
}
